import React from "react";

export const LegwegLogo: React.FC = () => {
  return (
    <div style={{ paddingTop: 36 }}>
      <span style={{ fontSize: 48, fontWeight: 900 }}>
        <span style={{ color: "white" }}>LEG</span>
        <span style={{ color: "#FFD561" }}>WEG</span>
      </span>
      <br />

      <span style={{ color: "#75318C", fontWeight: 900 }}>DIE TELEFOON</span>
    </div>
  );
};
