import { CAT_NAMES, groupedByCategory, sampleData } from "../data/sample-data";
import { CenterContent, PrimaryButton } from "./components/styled";
import React, { useState } from "react";

import { ReactComponent as DropdownTriangle } from "../icons/triangle-icon.svg";
import { LegwegLogo } from "../components/Logo";
import { Link } from "wouter";
import { PenaltyModal } from "../components/PenaltyModal";
import _ from "lodash";

const DEFAULT_GROUP = "1";

export const GetStarted: React.FC<{ onIdle: (category: string) => void }> = ({
  onIdle,
}) => {
  const groups = new Set(sampleData.map((d) => d.category));

  const [selectedCategory, setSelectedCategory] = useState(DEFAULT_GROUP);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <LegwegLogo />

      <div style={{ marginTop: 36 }}></div>
      <div style={{ color: "white", fontSize: 30, fontWeight: 900 }}>
        STAP 1
      </div>
      <div style={{ marginTop: 12 }}></div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            color: "white",
            textAlign: "center",
            fontSize: 12,
            width: 240,
            fontWeight: "bold",
          }}
        >
          IEDEREEN VAN HET GEZELSCHAP OPENT DEZE PAGINA EN SELECTEERT DEZELFDE
          CATEGORIE HIERONDER (CORONA PROOF..)
        </div>
      </div>

      <div style={{ marginTop: 24 }}></div>

      <div className="dropdown">
        <div
          className="dropdown-text"
          onClick={() => setOpenDropdown((v) => !v)}
        >
          {_.startCase(CAT_NAMES[selectedCategory])}
          <span
            style={{
              right: 20,
              position: "absolute",
              top: openDropdown ? 23 : 16,
              transform: openDropdown ? `rotateX(180deg)` : undefined,
            }}
          >
            <DropdownTriangle />
          </span>
        </div>
        {openDropdown && (
          <div className="dropdown-content">
            {Array.from(groups).map((cat) => (
              <p
                key={cat}
                style={{
                  fontWeight: cat === selectedCategory ? "bold" : "inherit",
                }}
                onClick={() => {
                  setSelectedCategory(cat);
                  setOpenDropdown(false);
                }}
              >
                {_.startCase(CAT_NAMES[cat])}
              </p>
            ))}
          </div>
        )}
      </div>

      <div style={{ marginTop: 12 }}></div>

      <span
        id="modal-trigger"
        onClick={() => setOpenModal(true)}
        style={{
          color: "#ffd561",
          fontSize: 14,
          textDecoration: "underline",
        }}
      >
        Bekijk de opdrachten van deze groep
      </span>

      {openModal && (
        <PenaltyModal
          assignments={groupedByCategory[selectedCategory]}
          onClose={() => setOpenModal(false)}
        />
      )}

      <div style={{ marginTop: 46 }}></div>

      <div
        style={{
          height: 300,
          background: "white",
          borderRadius: 60,
          maxWidth: 480,
          margin: "auto",
        }}
      >
        <div
          style={{
            color: "#75318C",
            paddingTop: 21,
            fontSize: 30,
            fontWeight: 900,
          }}
        >
          STAP 2
        </div>

        <div style={{ marginTop: 12 }}></div>
        <CenterContent
          style={{
            color: "#747383",
          }}
        >
          <div style={{ width: 242, fontSize: 12, fontWeight: 600 }}>
            KLIK OP DE KNOP HIERONDER OM TE STARTEN, ZET JE SCHERM UIT EN LEGWEG
            DIE TELEFOON!
          </div>
        </CenterContent>

        <div style={{ marginTop: 30 }}></div>

        <div>
          <PrimaryButton
            id="trigger-idle"
            onClick={() => onIdle(selectedCategory)}
          >
            Legweg
          </PrimaryButton>
        </div>

        <div style={{ marginTop: 12 }}></div>

        <div>
          <Link href="/" style={{ color: "#75318C", fontSize: 14 }}>
            Hoe werkt het?
          </Link>
          <br />
          <br />
          <a
            href="mailto:legwegapp@gmail.com?subject=suggesties voor legweg.nl"
            style={{ color: "#75318C", fontSize: 14 }}
          >
            Stuur leuke suggesties
          </a>
        </div>
      </div>
    </div>
  );
};
