import React, { useEffect, useRef } from "react";

import { LegwegLogo } from "../components/Logo";
import { useLocation } from "wouter";
import { usePageVisibility } from "react-page-visibility";

export const Idle: React.FC<{ onLeaving: (delta: number) => void }> = ({
  onLeaving,
}) => {
  const isVisible = usePageVisibility();

  const lastHide = useRef<Date>();

  const [, setLocation] = useLocation();

  useEffect(() => {
    if (!isVisible) {
      lastHide.current = new Date();
    }

    if (lastHide.current && isVisible) {
      const delta = new Date().getTime() - lastHide.current.getTime();
      onLeaving(delta);
    }
  }, [isVisible, onLeaving, lastHide, setLocation]);

  return (
    <div
      style={{
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <LegwegLogo />
      </div>
    </div>
  );
};
