import {
  CardContainer,
  CardDescriptionTitle,
  CenterContent,
  FirstSubCardRow,
  MainTitle,
  PrimaryButton,
  SecondSubCardRow,
  SecondaryTitle,
  SubCard,
  SubCardDescription,
  SubCardTitle,
} from "./components/styled";

import { ReactComponent as HandLogoStep1 } from "../icons/hand-icon.svg";
import { ReactComponent as LegwegCard } from "../icons/legweg-icon-step.svg";
import { LegwegLogo } from "../components/Logo";
import { Link } from "wouter";
import { ReactComponent as PersonIcon } from "../icons/person-icon.svg";
import React from "react";
import { ReactComponent as TabletIcon } from "../icons/tablet-icon.svg";

export const Home: React.FC = () => {
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <LegwegLogo />
      <div style={{ marginTop: 36 }}></div>
      <SecondaryTitle>OF KRIJG EEN OPDRACHT</SecondaryTitle>
      <div style={{ marginTop: 12 }}></div>

      <Link href="/get-started">
        <PrimaryButton>KLIK OM DIRECT TE STARTEN</PrimaryButton>
      </Link>
      <div style={{ marginTop: 36 }}></div>

      <MainTitle>HOE WERKT HET?</MainTitle>

      <div style={{ marginTop: 60 }}></div>

      <CardContainer>
        <FirstSubCardRow>
          <SubCard>
            <div>
              <LegwegCard />
              <br />
              <SubCardTitle>OPEN LEGWEG.NL</SubCardTitle>
              <br />
              <SubCardDescription>
                Iedereen klikt op 'direct starten' en selecteert dezelfde
                categorie
              </SubCardDescription>
            </div>
          </SubCard>
          <SubCard>
            <div>
              <TabletIcon />
              <br />
              <SubCardTitle>LEG WEG DIE TELEFOON!</SubCardTitle>
              <br />
              <SubCardDescription>
                Klik nu op de 'leg weg' knop, zet je scherm uit en LEGWEG die
                phone!
              </SubCardDescription>
            </div>
          </SubCard>
        </FirstSubCardRow>
        <SecondSubCardRow>
          <SubCard>
            <div>
              <HandLogoStep1 />
              <br />
              <span
                style={{ fontSize: 10, fontWeight: "bold", color: "#6D1C6C" }}
              >
                KRIJG SPIJT ;)
              </span>
              <br />
              <i style={{ fontSize: 10 }}>
                Toch even stiekem kijken? LEGWEG geeft je een verplichte
                opdracht.
              </i>
            </div>
          </SubCard>
          <SubCard>
            <div>
              <PersonIcon />
              <br />
              <SubCardTitle>VOER OPDRACHT UIT</SubCardTitle>
              <br />
              <SubCardDescription>
                Toon je opdracht aan je gezelschap en voer hem direct uit!
              </SubCardDescription>
            </div>
          </SubCard>
        </SecondSubCardRow>
        <CenterContent>
          <div>
            <CardDescriptionTitle>
              GEEN ZIN IN HET UITVOEREN VAN EEN STRAF?
            </CardDescriptionTitle>

            <div style={{ marginTop: 6 }}></div>

            <CenterContent>
              <div style={{ fontSize: 10, width: 300 }}>
                Doneer een bedrag! Wij sturen je door naar een goed doel op
                geef.nl. Wel even bewijzen aan je gezelschap!
              </div>
            </CenterContent>
          </div>
        </CenterContent>
      </CardContainer>

      <div style={{ marginBottom: 24 }}></div>
    </div>
  );
};
