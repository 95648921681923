import Papa from "papaparse";
import _ from "lodash";
import raw from "raw.macro";

const isBrowser = typeof window !== "undefined";

const csvData = (isBrowser
  ? raw("./sheets/english.tsv")
  : require("fs").readFileSync(__dirname + "/sheets/english.tsv")
)
  .toString()
  .trim()
  .replace("Categories", "category")
  .replace("Icon Name", "icon_name")
  .replace("Assignment name", "assignment_name")
  .replace(" Restart and Resocializ again", "")
  .replace("Assignment text", "assignment_text");

export const CAT_NAMES: Record<string, string> = {
  "1": "Eten en drinken",
  "2": "Video Call drinks",
  "3": "Do or dare 18plus",
  "4": "Dobbelen",
};

function parseData(csvContent: string): any[] {
  const data = Papa.parse(csvContent, { header: true });

  return data.data.map((d: any) => ({
    ...d,
    catName: CAT_NAMES[d.category] ?? null,
  }));
}

parseData(csvData);

export interface IAssignment {
  category: string;
  catName: string;
  icon_name: string;
  assignment_name: string;
  assignment_text: string;
}

export const sampleData: IAssignment[] = parseData(csvData);

export const groupedByCategory = _.groupBy(sampleData, "category");
