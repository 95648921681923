import styled from "styled-components";

export const CenterContent = styled.div`
  display: flex;
  justify-content: center;
`;

export const CardContainer = styled.div`
  height: 396px;
  maxwidth: 480px;
  margin: auto;
  border-radius: 60px;
  background: -webkit-linear-gradient(270deg, white 80%, #d8d8d8 20%);
  display: grid;
  grid-template-rows: 40% 40% 20%;
`;

export const MainTitle = styled.div`
  font-size: 30px;
  font-weight: 900;
  color: white;
`;

export const SuperTitle = styled.div`
  font-size: 48px;
  font-weight: 900;
  color: white;
`;

export const SecondaryTitle = styled.div`
  color: white;
`;

export const PrimaryButton = styled.button`
  background: #ffd561;
  border-radius: 29px;
  padding: 24px;
  font-size: 18px;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  font-weight: 900;
  color: #75318c;
  min-width: 240px;
`;

export const SubCard = styled(CenterContent)`
  width: 120px;
  flex-direction: column;
  align-items: center;
`;

export const FirstSubCardRow = styled.div`
  display: flex;
  top: -36px;
  position: relative;
  justify-content: space-around;
`;

export const SecondSubCardRow = styled.div`
  display: flex;
  top: -5%;
  position: relative;
  justify-content: space-around;
`;

export const SubCardTitle = styled.span`
  font-size: 10px;
  font-weight: bold;
  color: #6d1c6c;
`;

export const SubCardDescription = styled.i`
  font-size: 10px;
`;

export const CardDescriptionTitle = styled.div`
  font-size: 10px;
  margin-top: 12px;
  font-weight: bold;
  color: #6d1c6c;
`;
