import "./transitions.css";

import React, { useState } from "react";

import { CSSTransition } from "react-transition-group";
import { Finish } from "./Finish";
import { GetStarted } from "./GetStarted";
import { Idle } from "./Idle";

export const GetStartedContainer: React.FC = () => {
  const [, setDelta] = useState<number>();
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState<"idle" | "get-started" | "finish">(
    "get-started"
  );

  return (
    <CSSTransition
      key={location}
      in={true}
      timeout={200}
      appear
      classNames="page"
    >
      {location === "get-started" ? (
        <GetStarted
          onIdle={(_category) => {
            setCategory(_category);
            setLocation("idle");
          }}
        />
      ) : location === "idle" ? (
        <Idle
          onLeaving={(_delta) => {
            setDelta(_delta);
            setLocation("finish");
          }}
        />
      ) : (
        <Finish
          onRestart={() => setLocation("get-started")}
          onIdleRestart={() => setLocation("idle")}
          category={category}
        />
      )}
    </CSSTransition>
  );
};
