import "./PenaltyModal.css";

import { IAssignment } from "../data/sample-data";
import React from "react";
import _ from "lodash";

export const PenaltyModal: React.FC<{
  onClose: VoidFunction;
  assignments: IAssignment[];
}> = ({ onClose, assignments }) => {
  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <strong>{_.startCase(assignments[0].catName)}</strong>
        {assignments.map((a, i) => (
          <p key={i}>{a.assignment_name}</p>
        ))}
      </div>
    </div>
  );
};
