import { IAssignment, sampleData } from "../data/sample-data";
import { PrimaryButton, SuperTitle } from "./components/styled";
import React, { useEffect, useRef, useState } from "react";

import { LegwegLogo } from "../components/Logo";
import { ReactComponent as MoneyLogo } from "../icons/money-icon.svg";
import _ from "lodash";
import { usePageVisibility } from "react-page-visibility";

const findNextDifferentAssignment = (
  assignments: IAssignment[],
  currentAssignment?: IAssignment
): IAssignment | undefined => {
  const task = _.sample(assignments);

  if (task?.assignment_name === currentAssignment?.assignment_name) {
    return findNextDifferentAssignment(assignments, currentAssignment);
  }

  return task;
};

export const Finish: React.FC<{
  category: string;
  onIdleRestart: VoidFunction;
  onRestart: VoidFunction;
}> = ({ category, onIdleRestart, onRestart }) => {
  const [task, setTask] = useState<IAssignment>();

  useEffect(() => {
    const possibleTasks = sampleData.filter(
      (tasks) => tasks.category === category
    );

    // For stable screenshots we need to have also stable data
    const newTask: IAssignment | undefined = (window as any).Cypress
      ? possibleTasks[0]
      : findNextDifferentAssignment(possibleTasks, task);

    if (newTask) {
      setTask(newTask);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTask, category]);

  const lastHide = useRef<Date>();

  const isVisible = usePageVisibility();

  useEffect(() => {
    if (!isVisible) {
      lastHide.current = new Date();
    }

    if (lastHide.current && isVisible) {
      const possibleTasks = sampleData.filter(
        (tasks) => tasks.category === category
      );

      // For stable screenshots we need to have also stable data
      const newTask = (window as any).Cypress
        ? possibleTasks[0]
        : findNextDifferentAssignment(possibleTasks, task);

      if (newTask) {
        setTask(newTask);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, lastHide]);

  return (
    <div style={{}}>
      <div>
        <div style={{ paddingTop: 40 }}></div>
        <SuperTitle>HELAAS…</SuperTitle>
        <div style={{ marginTop: 60 }}></div>

        <div
          style={{
            minHeight: 316,
            background: "white",
            borderRadius: 60,
            width: "100%",
            maxWidth: 480,
            margin: "auto",
          }}
        >
          <MoneyLogo style={{ transform: "translate3d(0px, -30%, 0)" }} />

          <div
            style={{
              fontSize: 40,
              marginTop: -12,
              fontWeight: 900,
              color: "#75318C",
              paddingBottom: 32,
              paddingRight: 24,
              paddingLeft: 24,
            }}
          >
            {task?.assignment_name}
          </div>
          <p
            style={{
              fontSize: 14,
              marginTop: -12,
              fontWeight: 300,
              paddingRight: 20,
              paddingLeft: 20,
              color: "#666",
            }}
          >
            {task?.assignment_text}
          </p>

          <br />
          <a
            href="https://kika.onlinecollecteren.nl/collectebussen/wouter-meeuwisse-kika"
            target="_blank"
            rel="noreferrer"
          >
            <PrimaryButton>OF DONEER OP GEEF.NL</PrimaryButton>
          </a>

          <div style={{ marginTop: 10 }}></div>

          <div
            style={{
              fontStyle: "italic",
              fontSize: 14,
              paddingBottom: 32,
            }}
          >
            Wij hebben deze maand KIKA geselecteerd
          </div>
        </div>

        <div style={{ marginTop: 30 }}></div>

        <div style={{ marginTop: 10 }}></div>

        <br />
        <div
          onClick={onIdleRestart}
          style={{
            fontSize: 18,
            textDecoration: "underline",
            color: "white",
          }}
        >
          Weer tijd om sociaal te doen? <br />
          Zet je scherm weer uit!
        </div>

        <div style={{ marginTop: 30 }}></div>

        <span onClick={onRestart}>
          <LegwegLogo />
        </span>
      </div>
    </div>
  );
};
