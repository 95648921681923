import "./App.css";

import { GetStartedContainer, Home } from "./pages";

import React from "react";
import { Route } from "wouter";

function App() {
  return (
    <div className="App">
      <Route path="/" component={Home} />
      <Route path="/get-started" component={GetStartedContainer} />
    </div>
  );
}

export default App;
